<template>
    <a-layout class="mh-full">
        <a-layout-sider
            theme="light"
            breakpoint="lg"
            collapsed-width="0"
        >
            <div class="logo" >
                工具库
            </div>
            <a-menu
                mode="inline"
                v-model:selectedKeys="selectedKeys"
                @click="onItemClick"
            >
                <a-menu-item key="encrypt">
                    <user-outlined />
                    <span class="nav-text">MD5加密</span>
                </a-menu-item>
                <a-menu-item key="charGen">
                    <video-camera-outlined />
                    <span class="nav-text">字符生成</span>
                </a-menu-item>
                <a-menu-item key="excel">
                    <upload-outlined />
                    <span class="nav-text">EXCEL解析</span>
                </a-menu-item>
                <a-menu-item key="str">
                    <user-outlined />
                    <span class="nav-text">字符比较</span>
                </a-menu-item>
                <a-menu-item key="svgtopng">
                    <user-outlined />
                    <span class="nav-text">Svg到Png</span>
                </a-menu-item>
            </a-menu>
        </a-layout-sider>
        <a-layout>
            <a-layout-header :style="{ background: '#fff', padding: 0 }" />
            <a-layout-content :style="{ margin: '24px 16px 0' }">
                <div
                    :style="{
                        padding: '24px',
                        background: '#fff',
                        minHeight: '360px'
                    }"
                >
                    <router-view></router-view>
                </div>
            </a-layout-content>
            <a-layout-footer style="text-align: center">
                Zheng Utils ©2022 Created by WANG ZHENG
            </a-layout-footer>
        </a-layout>
    </a-layout>
</template>
<script>
import {
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined
} from '@ant-design/icons-vue'
export default {
    components: {
        UserOutlined,
        VideoCameraOutlined,
        UploadOutlined
    },
    data () {
        return {
            selectedKeys: []
        }
    },
    watch: {
        '$route.name' () {
            this.selectedKeys = [this.$route.name]
        }
    },
    methods: {
        onItemClick (data) {
            this.$router.push({ name: data.key })
        }
    }
}
</script>
<style>
.logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: #fff;
}
</style>
