import { createRouter, createWebHistory } from 'vue-router'
import Encrypt from '../views/encrypt/Encrypt.vue'
import CharGen from '../views/chargen/CharGen.vue'
import Excel from '../views/excel/Excel.vue'

const routes = [
    {
        path: '/',
        name: 'encrypt',
        component: Encrypt
    },
    {
        path: '/excel',
        name: 'excel',
        component: Excel
    },
    {
        path: '/charGen',
        name: 'charGen',
        component: CharGen
    },
    {
        path: '/str',
        name: 'str',
        component: () => import('../views/str/StrCompare.vue')
    },
    {
        path: '/svgtopng',
        name: 'svgtopng',
        component: () => import('../views/svgtopng/SvgToPng.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
