<template>
    <div>
        <a-row  :gutter="32">
            <a-col :span="12">
                <a-textarea v-model:value="word" @change="wordChange" placeholder="待加密字符" :rows="6" />
            </a-col>
            <a-col :span="12">
                <a-textarea v-model:value="code" placeholder="MD5加密串" disabled :rows="6" />
            </a-col>
        </a-row>
    </div>
</template>
<script>
import calcMD5 from 'utils/md5/md5'
export default {
    name: 'E-Encrypt',
    data () {
        return {
            word: '',
            code: ''
        }
    },
    methods: {
        wordChange () {
            if (this.word) {
                this.code = calcMD5(this.word)
            } else {
                this.code = ''
            }
        }
    }
}
</script>
<style>

</style>
