<template>
    <div>
        <a-divider>NANOID</a-divider>
        <a-row :gutter="32">
            <a-col :span="12">
                <a-form
                    :label-col="{ style: { width: '150px' } }"
                    :model="formData"
                >
                    <a-form-item label="自定义字符集">
                        <a-input
                            v-model:value="formData.customCode"
                            placeholder="自定义字符集"
                        />
                    </a-form-item>
                    <a-form-item label="自定义长度">
                        <a-input-number
                            style="width: 100%;"
                            v-model:value="formData.codeWidth"
                            placeholder="自定义长度"
                        />
                    </a-form-item>
                    <a-form-item style="text-align: right;">
                        <a-button @click="generate" type="primary">生成</a-button>
                    </a-form-item>
                </a-form>
            </a-col>
            <a-col :span="12">
                <a-textarea
                    v-model:value="formData.code"
                    placeholder="nanoid字符串"
                    disabled
                    :rows="6"
                />
            </a-col>
        </a-row>
        <a-divider>UUID</a-divider>
        <div style="margin: 30px;">
            <a-typography-title copyable style="text-align: center;">{{ uuidData.uuidCode }}
            </a-typography-title>
        </div>
        <div style="display: flex;justify-content: center;">
            <a-form
                :label-col="{ style: { width: '150px' } }"
                :model="uuidData"
            >
                <a-form-item label="生成类型">
                    <a-radio-group v-model:value="uuidData.type" option-type="button" :options="uuidTypeOptions" @change="uuidGenerate" />
                    <a-button style="margin-left: 20px;" @click="uuidGenerate" type="primary">生成</a-button>
                </a-form-item>
                <a-form-item v-if="['v3', 'v5'].indexOf(uuidData.type) > -1" label="NAME">
                    <a-input
                        v-model:value="uuidData.name"
                        placeholder="name"
                    />
                </a-form-item>
                <a-form-item v-if="['v3', 'v5'].indexOf(uuidData.type) > -1" label="命名空间">
                    <a-input
                        style="width: 100%;"
                        v-model:value="uuidData.namespace"
                        placeholder="命名空间"
                    />
                </a-form-item>
            </a-form>
        </div>
    </div>
</template>
<script>
import { nanoid, customAlphabet } from 'nanoid'
import * as uuid from 'uuid'
export default {
    name: 'E-Encrypt',
    data () {
        return {
            formData: {
                type: 'v4',
                codeWidth: '',
                customCode: '',
                code: ''
            },
            uuidData: {
                type: 'v4',
                uuidCode: uuid.v4(),
                name: '',
                namespace: ''
            },
            uuidTypeOptions: [
                { label: '空字符串', value: 'nil' },
                { label: 'v1（时间戳）', value: 'v1' },
                { label: 'v3（命名空间）', value: 'v3' },
                { label: 'v4（随机数）', value: 'v4' },
                { label: 'v5（命名空间）', value: 'v5' }
            ]
        }
    },
    methods: {
        generate () {
            if (this.formData.customCode && this.formData.codeWidth) {
                this.formData.code = customAlphabet(this.formData.customCode, this.formData.codeWidth)()
            } else {
                this.formData.code = nanoid()
            }
        },
        uuidGenerate () {
            switch (this.uuidData.type) {
            case 'nil':
                this.uuidData.uuidCode = uuid.NIL
                break
            case 'v1':
                this.uuidData.uuidCode = uuid.v1()
                break
            case 'v3':
                if (this.uuidData.name && this.uuidData.namespace && this.uuidData.namespace.length === 36) {
                    this.uuidData.uuidCode = uuid.v3(this.uuidData.name, this.uuidData.namespace)
                }
                break
            case 'v4':
                this.uuidData.uuidCode = uuid.v4()
                break
            case 'v5':
                if (this.uuidData.name && this.uuidData.namespace && this.uuidData.namespace.length === 36) {
                    this.uuidData.uuidCode = uuid.v5(this.uuidData.name, this.uuidData.namespace)
                }
                break
            default:
                this.uuidData.uuidCode = uuid.v4()
                break
            }
        }
    }
}
</script>
<style></style>
