<template>
    <a-upload-dragger
        v-model:fileList="fileList"
        name="file"
        :before-upload="beforeUpload"
        :maxCount="1"
        accept=".xlsx, .xls"
        @change="handleChange"
        @drop="handleDrop"
    >
        <p class="ant-upload-drag-icon">
            <inbox-outlined></inbox-outlined>
        </p>
        <p class="ant-upload-text">点击或者把EXCEL文件拖入指定区域，进行解析。</p>
    </a-upload-dragger>
    <a-radio-group v-model:value="currentSheetName" v-if="workBook.SheetNames && workBook.SheetNames.length > 0" button-style="solid" style="margin-top: 20px;" @change="sheetChange">
        <a-radio-button v-for="sheetName of workBook.SheetNames" :value="sheetName" :key="sheetName">{{ sheetName }}</a-radio-button>
    </a-radio-group>
    <a-textarea :rows="25" v-model:value="excelJson" style="margin: 20px 0;" />
    <a-button @click="downloadExcel()" type="primary">导出EXCEL</a-button>
</template>
<script>
import { InboxOutlined } from '@ant-design/icons-vue'
import { ref } from 'vue'
import { read, utils, writeFile } from 'xlsx'
import dayjs from 'dayjs'
export default {
    name: 'e-excel',
    components: {
        InboxOutlined
    },
    setup () {
        return {
            fileList: ref([]),
            handleDrop: e => {
                console.log(e)
            },
            beforeUpload () {
                return false
            }
        }
    },
    data () {
        return {
            excelJson: '',
            workBook: {},
            currentSheetName: ''
        }
    },
    methods: {
        handleChange (info) {
            const status = info.file.status
            if (status !== 'uploading') {
                this.readFile(info.file)
            }
        },
        readFile (f) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.workBook = read(e.target.result, {
                    cellDates: true
                })
                this.currentSheetName = this.workBook.SheetNames[0]

                this.sheetChange()
            }
            reader.readAsArrayBuffer(f)
        },
        sheetChange () {
            this.excelJson = JSON.stringify(utils.sheet_to_json(this.workBook.Sheets[this.currentSheetName]), null, 4)
        },
        downloadExcel () {
            if (!this.excelJson) return;

            const exportJson = JSON.parse(this.excelJson);

            if (Array.isArray(exportJson) && exportJson.length > 0) {
                // 创建一个工作簿
                const workbook = utils.book_new();

                // 将 JSON 数据转换为工作表
                const worksheet = utils.json_to_sheet(exportJson);

                // 将工作表添加到工作簿
                utils.book_append_sheet(workbook, worksheet, 'Sheet1');

                // 将工作簿写入 Blob 对象
                writeFile(workbook, `导出内容${dayjs().format('YYYYMMDDHHmmss')}.xlsx`);
            }
        }
    }
}
</script>
